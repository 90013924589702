import styled from 'styled-components/macro';
import PageBreadcrumb from '../components/PageBreadcrumb';
import Content from '../components/Content';
import {colors, media, Notice, PrimaryButton, ProgressBarStyle, th} from '@snsw/react-component-library';
import Progress from '../components/Progress';
import ContentHeader from '../components/ContentHeader';
import PageTitle from '../components/PageTitle';
import Actions from '../components/Actions';
import FullWidthContent from '../components/FullWidthContent';
import SideBar from '../components/SideBar';
import Title from '../components/Title';
import {Flex} from '../../..';
import PreFooter from '../components/PreFooter';

export const GELTransactionLPageLayoutStyled = styled.main`
  background-color: ${props => props.theme.colors.white};
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: calc((100vw - 76.5rem)/2) calc(76.5rem * 0.7) calc(76.5rem * 0.3) calc((100vw - 76.5rem)/2);
  grid-template-columns: auto minmax(20rem, calc(76.5rem * 0.6)) minmax(20rem, calc(76.5rem * 0.4)) auto;
  grid-template-areas:
    "progress        progress               progress             progress"       
    "l-grey          page-title             page-title           page-title"  
    "l-grey          breadcrumb             breadcrumb           breadcrumb"  
    "l-grey          page-heading           page-heading         page-heading"
    ".               content-title          .                           ."
    ".               content                side-bar                    ."
    ".               full-width-content     full-width-content          ."
    ".               profile                profile                     ."
    ".               actions                .                           ."
    ".               pre-footer             pre-footer                  .";
    
  @media (max-width: ${media.lg}px) {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    grid-template-areas:      
      "progress"
      "page-title"
      "breadcrumb" 
      "page-heading" 
      "content-title"
      "content"
      "side-bar"
      "full-width-content"
      "profile"
      "actions"
      "pre-footer";
  }
  
  ::before, ::after {
    margin-bottom: 16px;
    grid-area: l-grey;
    content: '';
    background-color: ${props => props.theme.colors.greyBackground};
  }

  ::before {
    //for ie11 only
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: auto;
      height: auto;
      -ms-grid-row-span: 2;
      display: block;
      background-color: ${props => props.theme.colors.greyBackground};
    }
  }
  
  ${PageBreadcrumb}, ${PageTitle}, ${ContentHeader}, ${Progress} {
    background-color: ${props => props.theme.colors.greyBackground};
    }
  
  ${Progress} {
    grid-area: progress;
    
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    
    @media (max-width: ${media.md}px) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
     }
    
    ${ProgressBarStyle.FullBar} {
      margin-top: 0;
      height:6px; 
    }
  }
  
  ${PageTitle} {
    grid-area: page-title;
    
    -ms-grid-row: 2;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    
    @media (max-width: ${media.md}px) {
        -ms-grid-row: 2;
        -ms-grid-column: 1;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
     }
     
    padding-left: 1.875rem;
    c-right: 1.875rem;
    line-height: 24px;
  }
  
  ${PageBreadcrumb} {
    grid-area: breadcrumb;
    
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span:1;
    
     @media (max-width: ${media.md}px) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        padding-right: 1.25rem;
        padding-left: 1.25rem;
     }
    
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-top: ${th[1]};
    padding-bottom: ${th[1]};
  }
    
  ${ContentHeader}{
    grid-area: page-heading;
    
    -ms-grid-row: 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
    
     @media (max-width: ${media.md}px) {
        -ms-grid-row: 4;
        -ms-grid-column: 1;
     }
     
    padding-top: 8px;
    padding-bottom: 40px;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    margin-bottom: 16px;
    
    @media (max-width: ${media.md}px) {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    
    > h1 {
      line-height: 60px;
    }
    
    > hr {
      border: 2px solid rgb(213, 213, 213);
      margin-top: 16px;
    }
  }
  
  ${Title} {
    grid-area: content-title;
    -ms-grid-row: 5;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1; 
    
     @media (max-width: ${media.md}px) {
        -ms-grid-row: 5;
        -ms-grid-column: 1;
     }
     
    margin-top: ${th[1]};
    margin-bottom: ${th[1]};
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  
  ${Content} {
    grid-area: content;
    -ms-grid-row: 6;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1; 
    
     width: 100%;
    
     @media (max-width: ${media.md}px) {
        -ms-grid-row: 6;
        -ms-grid-column: 1;
        
     ${Flex}{
            flex-direction:column
        }
     }
     
     > * {
            padding-bottom: 0.5rem;
        }

    ${Notice}{
        max-width:38rem;
    }
    
    ${Notice} > a, ${Notice.Copy} > a {
      color: ${colors.blue}
    }
  
    margin-top: ${th[1]};
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    
    @media (max-width: ${media.md}px) {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
  }
  
    
  ${SideBar}{
  grid-area: side-bar;
    -ms-grid-row: 6;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    
    @media (max-width: ${media.md}px) {
      -ms-grid-row: 6;
      -ms-grid-column: 1;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
  }
  
   ${FullWidthContent} {
    grid-area: full-width-content;
    -ms-grid-row: 7;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2; 
   
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    max-width: ${props => props.theme.componentWidths.screener.full};
    
    @media (max-width: ${media.md}px) {
      -ms-grid-row: 7;
      -ms-grid-column: 1;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      margin-top: 0;

      ${Flex} {
        flex-direction:column
      }
    }
  }
  
  ${Actions} {
    grid-area: actions;
    -ms-grid-row: 9;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1; 
    
    margin-bottom: 1.5rem;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
    
    width: 100%;
    max-width: 42rem;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    @media (max-width: ${media.md}px) {
      -ms-grid-row: 8;
      -ms-grid-column: 1;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    
    @media (max-width: ${media.xs}px) {
      ${PrimaryButton} {
        width: 100%;
      }
    }
  }
  
  ${PreFooter} {
    grid-area: pre-footer;
    -ms-grid-row: 10;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2; 
    
     @media (max-width: ${media.md}px) {
      -ms-grid-row: 9;
      -ms-grid-column: 1;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    margin-bottom: 3.5rem;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
`;
