import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import './styles.css';

const DEFAULT_PLACEHOLDER_STRING = 'Select...';

class Dropdown extends Component {
    constructor (props) {
        super(props);
        this.state = {
            selected: this.parseValue(props.value, props.options) || {
                label: typeof props.placeholder === 'undefined' ? DEFAULT_PLACEHOLDER_STRING : props.placeholder,
                value: ''
            },
            isOpen: false
        };
        this.dropdownRef = createRef();
        this.mounted = true;
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.fireChangeEvent = this.fireChangeEvent.bind(this);
    }

    componentDidUpdate (prevProps) {
        if (this.props.value !== prevProps.value) {
            if (this.props.value) {
                const selected = this.parseValue(this.props.value, this.props.options);
                if (selected !== this.state.selected) {
                    this.setState({ selected });
                }
            } else {
                this.setState({
                    selected: {
                        label: typeof this.props.placeholder === 'undefined' ? DEFAULT_PLACEHOLDER_STRING : this.props.placeholder,
                        value: ''
                    }
                });
            }
        }
    }

    componentDidMount () {
        document.addEventListener('click', this.handleDocumentClick, false);
        document.addEventListener('touchend', this.handleDocumentClick, false);
    }

    componentWillUnmount () {
        this.mounted = false;
        document.removeEventListener('click', this.handleDocumentClick, false);
        document.removeEventListener('touchend', this.handleDocumentClick, false);
    }

    handleMouseDown (event) {
        if (this.props.onFocus && typeof this.props.onFocus === 'function') {
            this.props.onFocus(this.state.isOpen);
        }
        if (event.type === 'mousedown' && event.button !== 0) {return;}
        event.stopPropagation();
        event.preventDefault();

        if (!this.props.disabled) {
            this.setState({
                isOpen: !this.state.isOpen
            });
        }
    }

    parseValue (value, options) {
        let option;

        if (typeof value === 'string') {
            for (var i = 0, num = options.length; i < num; i++) {
                if (options[i].type === 'group') {
                    const match = options[i].items.filter(item => item.value === value);
                    if (match.length) {
                        option = match[0];
                    }
                } else if (typeof options[i].value !== 'undefined' && options[i].value === value) {
                    option = options[i];
                }
            }
        }

        return option || value;
    }

    setValue (value, label) {
        const newState = {
            selected: {
                value,
                label},
            isOpen: false
        };
        this.fireChangeEvent(newState);
        this.setState(newState);
    }

    fireChangeEvent (newState) {
        if (newState.selected !== this.state.selected && this.props.onChange) {
            this.props.onChange(newState.selected);
        }
    }

    handleOptionKeyDown(handleOnSelect, event) {
        if (event.which === 32 || event.which === 13) {
            handleOnSelect();
            event.preventDefault();
            return false;
        }
    }

    renderOption (option) {
        let value = option.value;
        if (typeof value === 'undefined') {
            value = option.label || option;
        }
        const label = option.label || option.value || option;
        const handleOnSelect = option.onSelect
            ? () => {
                this.setState({isOpen: false});
                option.onSelect();
            }
            : this.setValue.bind(this, value, label);
        const isSelected = value === this.state.selected.value || value === this.state.selected;

        const classes = {
            [`${this.props.baseClassName}-option`]: true,
            [option.className]: !!option.className,
            'is-selected': isSelected
        };
        const optionClass = classNames(classes);

        return (
            <div
                selected={isSelected}
                tabIndex={0}
                onKeyUp={this.handleOptionKeyDown.bind(this, handleOnSelect)}
                key={value}
                className={optionClass}
                onMouseDown={handleOnSelect}
                onClick={handleOnSelect}
                role='option'
                aria-selected={isSelected ? 'true' : 'false'}>
                {label}
            </div>
        );
    }

    buildMenu () {
        const { options, baseClassName } = this.props;
        const ops = options.map((option) => {
            if (option.type === 'group') {
                const groupTitle = (<div className={`${baseClassName}-title`}>
                    {option.name}
                </div>);
                const _options = option.items.map((item) => this.renderOption(item));

                return (
                    <div className={`${baseClassName}-group`} key={option.name} role='listbox' tabIndex='-1'>
                        {groupTitle}
                        {_options}
                    </div>
                );
            } else {
                return this.renderOption(option);
            }
        });

        return ops.length ? ops : <div className={`${baseClassName}-noresults`}>
            No options found
        </div>;
    }

    handleDocumentClick (event) {
        if (this.mounted) {
            if (!this.dropdownRef.current.contains(event.target)) {
                if (this.state.isOpen) {
                    this.setState({ isOpen: false });
                }
            }
        }
    }

    handleMenuKeyDown (event) {
        if (event.which === 32 || event.which === 13) {
            this.setState({
                isOpen: !this.state.isOpen
            });

            event.preventDefault();
            return false;
        }
    }

    isValueSelected () {
        return typeof this.state.selected === 'string' || this.state.selected.value !== '';
    }

    render () {
        const { baseClassName, controlClassName, placeholderClassName, menuClassName, arrowClassName, arrowClosed, arrowOpen, className} = this.props;

        const disabledClass = this.props.disabled ? 'Dropdown-disabled' : '';
        const placeHolderValue = typeof this.state.selected === 'string' ? this.state.selected : this.state.selected.label;

        const dropdownClass = classNames({
            [`${baseClassName}-root`]: true,
            [className]: !!className,
            'is-open': this.state.isOpen
        });
        const controlClass = classNames({
            [`${baseClassName}-control`]: true,
            [controlClassName]: !!controlClassName,
            [disabledClass]: !!disabledClass
        });
        const placeholderClass = classNames({
            [`${baseClassName}-placeholder`]: true,
            [placeholderClassName]: !!placeholderClassName,
            'is-selected': this.isValueSelected()
        });
        const menuClass = classNames({
            [`${baseClassName}-menu`]: true,
            [menuClassName]: !!menuClassName
        });
        const arrowClass = classNames({
            [`${baseClassName}-arrow`]: true,
            [arrowClassName]: !!arrowClassName
        });

        const value = (<div className={placeholderClass}>
            {placeHolderValue}
        </div>);
        const menu = this.state.isOpen ? <div className={menuClass} aria-expanded='true'>
            {this.buildMenu()}
        </div> : null;

        return (
            <div ref={this.dropdownRef} className={dropdownClass}>
                <div className={controlClass} onMouseDown={this.handleMouseDown.bind(this)} onTouchEnd={this.handleMouseDown.bind(this)} aria-haspopup='listbox' onKeyDown={this.handleMenuKeyDown.bind(this)} tabIndex='0' >
                    {value}
                    <div className={`${baseClassName}-arrow-wrapper`}>
                        {arrowOpen && arrowClosed
                            ? this.state.isOpen ? arrowOpen : arrowClosed
                            : <span className={arrowClass} />}
                    </div>
                </div>
                {menu}
            </div>
        );
    }
}

Dropdown.defaultProps = { baseClassName: 'Dropdown' };

Dropdown.propTypes = {
    options: PropTypes.array ,
    baseClassName: PropTypes.string,
    className: PropTypes.string,
    controlClassName: PropTypes.string,
    placeholderClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    arrowClassName: PropTypes.string,
    disabled: PropTypes.bool,
    arrowClosed: PropTypes.element,
    arrowOpen: PropTypes.element,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.object,
    placeholder: PropTypes.string
};
export default Dropdown;